.menu-tab-configuration {
    margin-bottom: 10px;
    .item-tab-configuration {
        color: #000 !important;
        span {
            font-size: 16px;
        }
    }

    .item-tab-configuration.Mui-selected {
        border-bottom: 1px solid #1876d2;
    }

    .btn-save-table-config:hover {
        border: 0 !important;
    }
    .btn-save-table-config.Mui-disabled  {
        border: 0;
    }
}

.border-culoumm-right {
    border-right: 1px solid rgb(206, 209, 218);
    margin-top: auto;
    margin-bottom: auto;
    height: 25px;
    width: 1px;
}
