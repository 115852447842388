.json-data-config {
    .jsoneditor-menu {
        padding-left: 40px;
    }
    .jsoneditor-poweredBy {
        display: none !important;
    }
    position: relative;
    .jsoneditor-mode-tree {
        // .jsoneditor-expand-all {
        //     display: none;
        // }
        // .jsoneditor-collapse-all {
        //     display: none;
        // }
        .jsoneditor-sort {
            display: none;
        }

        .jsoneditor-transform {
            display: none;
        }
    }
    .btn-save-table-config {
        position: absolute;
        left: -10px;
        top: 13px;
        z-index: 1;
        width: 0px;
        height: 0px;
        color: #fff;
        border: 0;
    }

    .btn-save-table-config:hover {
        border: 0 !important;
    }

    .btn-save-table-config:disabled {
        border: 0 !important;
        color: #000;
    }
}