.container-loading {
    display: block;
    min-height: 100px;
    position: relative;
    height: 100%;
    .modal-loading {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        max-width: 100%;
        height: 40px;
        bottom: 0;
        text-align: center;
        // .circular {
        //     margin-top: 30px;
        // }
    }
}