@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;700&display=swap');

@font-face {
    font-family: 'icomoon';
    src: url('/assets/fonts/icomoon.eot?aun1wh');
    src: url('/assets/fonts/icomoon.eot?aun1wh#iefix') format('embedded-opentype'),
        url('/assets/fonts/icomoon.ttf?aun1wh') format('truetype'),
        url('/assets/fonts/icomoon.woff?aun1wh') format('woff'),
        url('/assets/fonts/icomoon.svg?aun1wh#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-alpagino"],
[class*=" icon-alpagino"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    // speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-edit:before {
  content: "\e900";
}
.icon-user:before {
  content: "\e901";
}
.icon-cancel:before {
  content: "\e902";
}
.icon-refresh:before {
  content: "\e903";
}
.icon-user-plus:before {
  content: "\e904";
}
