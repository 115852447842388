.master-auth {
    max-width: 100vw;
    max-height: 800px;
    // background: red;
    margin: auto;
    .page-auth {
        text-align: center;
        width: 75vw;
        // height: 90vh;
        background: white;
        margin: auto;
        display: flex;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 5px;
        box-shadow: 0px 10px 10px rgba(10, 27, 72, 0.1);
        height: 700px;
        .bg-img-left {
            position: relative;
            .logo {
                top: 50px;
                left: 50px;
                position: absolute;
            }
            .img-auth {
                object-fit: cover;
                width: 100%;
                height: 700px;
                border-radius: 6px 0 0 6px;
            }
        }

        .form-input-login {
            width: 90%;
            height:700px;
            margin: auto;
            text-align: left;
            display: flex;
            justify-content: center;
            flex-direction: column;
            input {
                border: 1px solid #CED1DA;
                border-radius: 8px;
                height: 50px;
            }
            .title-login {
                color: #0A1B48;
                font-size: 19px;
                font-weight: bold;
                line-height: 25px;
                margin-bottom: 30px;
            }
            .wrapper {
                position: relative;
                .icon {
                    width: 2rem;
                    // backgroundColor: 'red',
                    padding: 4px;
                    position: absolute;
                    box-sizing: border-box;
                    top: 50%;
                    left:   2px;
                    transform: translateY(-50%);
                }
                .input {
                    box-sizing: border-box;
                    padding-left: 2.5rem;
                }
            }

            .txt-link-pass {
                color: #0F24B5;
                font-size: 12px;
                font-style: normal;
                text-align: right;
                font-family: Inter;
                font-weight: 300;
            }

            .action-buttom {
                text-align: center;
                margin-top: 50px;

                .b-connect {
                    margin-bottom: 20px;

                }

                .txt-link {
                    color: #0F24B5;
                    font-size: 12px;
                    font-style: normal;
                    text-align: center;
                    font-family: Inter;
                    font-weight: 300;
                }
            }
        }
    }
    
}

.w-btn {
    width: 50%;
}

.mg-right-10 {
    margin-right: 10px !important;
}