.workspace-principal {
    .menu-tab-database {
        .item-tab {
            &.active {
                color: $info-midtone;
            }
        }
    }
    .menu-right {
        label {
            display: inline-block;
        }
    }
}