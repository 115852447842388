.table-list {
    background: $color-fff;
    tr {
        &.MuiTableRow-hover{
                &:hover {
                background-color: $accent02-10 !important;
            }
        }
        td, th {
            border-bottom: 0 !important;
        }
    }
    .pagination {
        .MuiV5-MuiTablePagination-toolbar {
            float: left;
        }
    }
}