// .tab-list-dashboard {
//     .Mui-selected {
//         color: rgb(25, 118, 210) !important;
//     }
//     .font-text-default {
//         text-transform: capitalize !important;
//         min-height: 50px;
//     }

//     .tab-dashboard {
//         display: block;
//     }

//     .MuiTabs-indicator {
//         background-color: rgb(25, 118, 210);
//     }
// }

.color-grey {
    color: rgba(0, 0, 0, 0.6) !important;
}

.tab-parent-categories {
    padding: 24px !important;
}