.dropzone {
    border: dashed darkgrey thin;
    padding: 16px;
    border-radius: 8px;
    height: 150px;
    img {
        max-height: 100%;
        max-width: 100%;
        object-fit: contain;
    }
}