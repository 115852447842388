.data-grid-alpagino {
	overflow-y: hidden;
	.MuiV5-MuiBox-root {
		padding: 0;
	}
	#data-grid-demo {
		min-height: 700px;
	}
	
	.options {
		margin-top: 20px;
		padding: 20px;
		background: #f5f5f5;
	}
	
	.options .caption {
		font-size: 18px;
		font-weight: 500;
	}
	
	.option {
		margin-top: 10px;
		display: flex;
		align-items: center;
	}
	
	.option > span {
		width: 120px;
		display: inline-block;
	}
	
	.option > .dx-widget {
		display: inline-block;
		vertical-align: middle;
		width: 100%;
		max-width: 350px;
	}

	// .dx-datagrid-rowsview .dx-select-checkboxes-hidden > tbody > tr > td > .dx-select-checkbox {
	// 	display: inline-block;
	// }

	// .dx-datagrid-checkbox-size.dx-checkbox-checked .dx-checkbox-icon {  
	// 	border: 1px solid #CED1DA;  
	// }  
	
}

.data-basededonee {
	td {
		height: 50px;
		vertical-align: revert !important;
	}

	.dx-header-row {
		background: #E7E9F8;
		.dx-datagrid-drag-action {
			.dx-datagrid-text-content {
				font-family: 'Inter';
				font-style: normal;
				font-weight: 700;
				font-size: 12px;
				line-height: 18px;
				color: #0A1B48;
			}
		}
	}

	.dx-data-row {
		td {
			font-family: 'Inter';
			font-style: normal;
			font-weight: 300;
			font-size: 12px;
			line-height: 18px;
			align-items: center;
			color: #000000;
		}
	}
	.dx-scrollable-scroll{
		background-color: rgb(177, 177, 177);
	}
}

.dx-datagrid-borders .dx-datagrid-rowsview, .dx-datagrid-headers + .dx-datagrid-rowsview, .dx-datagrid-rowsview.dx-datagrid-after-headers{
	border-top: 0 !important;
}
.acctive-filter-header {
	border-bottom: 2px solid #0d20a3 !important;
}

.popup-add-row-table {
	.MuiV5-MuiDialog-paper.MuiV5-MuiDialog-paperScrollPaper {
		max-width: 100%;
		width: calc(100% - 300px);
	}

	.input-form {
		textarea {
			padding: 10px;
			color: #071332;
			font-size: 16px;
			line-height: 24px;
		}
	}
	.input-text {
		input {
			padding: 10px;
			color: #071332;
			font-size: 16px;
			line-height: 24px;
		}

		
	}

	.ql-container {
		height: 100px;
	}
	
	.ql-editor.ql-blank {
		height: 100px;
	}

	.btn-cancle {
		background: #FFFFFF;
		border: 1px solid #CED1DA;
		border-radius: 8px;
		color: #0A1B48;
		font-family: 'Inter';
		font-style: normal;
		font-weight: 300;
		font-size: 16px;
		line-height: 24px;
	}

	.mr-50 {
        margin-right: 50px;
    }
	
	.btn-pri:hover {
		background: #F29C1C !important;
	}

	.input-create-table {
		margin-top: 8px;
		padding: 10px !important;
		font-size: 12px !important;    
		height: calc(1.5em + 1rem + 10px) !important;
	}
}

.dx-datagrid-header-panel {
	display: none;
}

.search-row-table {
	padding:'0px 24px';
	min-height: 40px;
	justify-content: center;
	margin-left: 10px !important;
}

.icon-search {
	cursor: default;
}

.list-project {
	width: 200px;
	margin-left: 8px !important;
	.MuiV5-MuiSelect-select {
		padding: 5px 14px;
	}
}

.dx-freespace-row {
	display: none;
}