.tab-database {
    // position: relative;
    .MuiV5-MuiBox-root {
        padding: 0;
    }
    .menu-tab-database {
        width: 100%;
        justify-content: left;
        height: 40px;
        .item-tab {
            max-width: 100px;
        }
        .icon-tab {
            max-width: 25px;
        }
        .border-culoumm-right {
            border-right: 1px solid rgb(206, 209, 218);
            margin-top: auto;
            margin-bottom: auto;
            height: 25px;
            width: 1px;
        }

        .btn-add-key {
            border: 0;
            color: #0a1b48;
        }

        .Mui-disabled {
            color: rgba(0, 0, 0, 0.26);
        }
    }

    
    
}

.btn-next {
    width: 45%;
    background: #248CCF !important;
    color: #fff !important;
    margin: auto !important;
}

.btn-done-import {
    width: 80%;
    background: #248CCF !important;
    color: #fff !important;
    margin: auto !important;
}

.steps-import {
    .Mui-completed {
        font-size: 24px;
        color: #FA7600 !important;
        font-weight: 700 !important;
    }

    .Mui-active {
        font-size: 24px;
        color: #FA7600 !important; 
        font-weight: 700 !important;
    }

    .Mui-disabled {
        font-size: 24px;
        font-weight: 400;
    }

    // circle {
    //     color: #fff;
    // }

    // text {
    //     color: #000;
    //     fill: #000;
    //     font-size: 24px;
    // }

    
}

.dialog-title-import {
    font-weight: 400 !important;
    color: #484D89;
    padding-bottom: 0px !important;
}

.dx-datagrid-rowsview .dx-data-row .dx-cell-modified.dx-cell-modified:after, .dx-datagrid-rowsview .dx-data-row .dx-cell-modified.dx-datagrid-invalid:after {
    border-color: rgb(24 118 210);
}

.my-dropzone {
    height: 100px;
}

.steps-import .Mui-disabled {
    font-size: 0.875rem;
}

.btn-close-popup {
    width: 35px;
    height: 35px;
    min-width: 20px !important;
    position: absolute !important;
    right: 0;
    border: 1px solid #000;
    padding: 0.2rem 0.62rem;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
    border-radius: 0.357rem;
    background: #fff;
    opacity: 1;
    transition: all .23s ease .1s;
    transform: translate(8px, -2px);
}

.btn-close-popup:hover {
    opacity: 1;
    outline: none;
    transform: translate(5px, 3px);
    box-shadow: none;
}

.btn-danger {
    color: #fff !important;
    background-color: #dc3545 !important;
    border-color: #dc3545 !important;
}

.popup-export-file-export {
    .MuiV5-MuiDialog-paperScrollPaper  {
        width: 500px;
    }
}

.popup-create-row-data-master-database {
    .MuiV5-MuiDialog-paperScrollPaper  {
        width: 80%;
        max-width: 100%;
    }
}

.data-grip-config-data {
    position: relative;
    .dx-datagrid-header-panel {
        display: block !important;
        .dx-toolbar {
            margin-top: 10px;
        }
        .dx-toolbar-after {
            right: auto;
        }
    }

    .btn-save-table-config {
        position: absolute;
        z-index: 1;
        left: 53px;
        width: 40px;
        top: 10px;
        height: 35px;
    }

    .dx-datagrid-header-panel {
        .dx-toolbar-after {
            .dx-toolbar-item:nth-child(n+2) {
                display: none;
            }
           
        }
    }
}

.action-footer {
    position: sticky;
    bottom: 0;
    background: #fff;
    padding: 20px 5px;
    -webkit-box-shadow: -1px -4px 5px 0px rgba(10, 27, 72, 0.15);
    -moz-box-shadow: -1px -4px 5px 0px rgba(10, 27, 72, 0.15);
    box-shadow: -1px -4px 5px 0px rgba(10, 27, 72, 0.15);
}

.databases-master-attributes {
    .item-column {
        height: 80px;
        vertical-align: middle !important;
    }
}
.mini-tools {
    position: absolute;
    top: 0;
    left: 400px;
    z-index: 9999;
    width: 320px;
}

.open-mini-tools {
    display: block;

}

.dropdown-mini-tools {
    position: relative;
    // top: 100px;
    z-index: 9999;
    background: #ced1da54;
    padding: 10px;
    border: 1px solid #ddd;
    bottom: 38px;
    height: 35px;
    overflow: scroll;
    width: 100%;
    display: flex;
    align-items: center;

    button {
        border: .5px solid #000;
        background: transparent;
        border-radius: 5px;
        img {
            width: 20px;
        }
    }

    button:hover {
       background: #fff;
    }
}

.item-column.dx-editor-cell {
    position: relative;
}

.item-column.dx-editor-cell {
    .dx-texteditor-container {
        position: relative;
        top: 30px;
    }
}