@import "variables";

$bg-primary-enable: $accent01-00;
$bg-primary-hover: $accent02-00;
$bg-primary-focus: $accent01-60;
$bg-primary-press: $accent01-80;
$bg-primary-disabled: $primary-midtone40;

$color-primary-enable: $primary-light00;
$color-primary-disabled: $primary-light90;

$bg-second-enable: $primary-light10;
$bg-second-hover: $primary-light10;
$bg-second-focus: $primary-light10;
$bg-second-press: $secondary40;
$bg-second-disabled: $primary-light10;
$border-input: 1px solid #CED1DA;

@mixin button-variant($type, $background) {
    background-color: $background !important;
    border-radius: 8px;
    padding: 12px 32px;

    @if $type == 'primary' {
        color: $primary-light00 !important;
        border: none;
    }  @else if $type == 'secondary' {
        color: $primary-dark00 !important;
        border: $border-input !important;
    } @else if $type == 'disabled' {
        color: $color-primary-disabled !important;
        border: $border-input !important;
    } @else {
        color: $primary-light00 !important;
        border: none;
    }

    @include hover {
        @if $type == 'primary' {
            background-color: $bg-primary-hover !important;
            box-shadow: 0px 10px 10px rgba(10, 27, 72, 0.1) !important;
        } @else if $type == 'secondary' {
            background-color: $bg-second-hover !important;
            box-shadow: 0px 10px 10px rgba(10, 27, 72, 0.1) !important;
        } @else if $type == 'disabled' {
            background-color: $bg-primary-disabled !important;
        }
    }
    @include focus {
        @if $type == 'primary' {
            background-color: $bg-primary-focus !important;
        } @else if $type == 'secondary' {
            background-color: $bg-second-focus !important;
            border: 1px solid #9DA4B6;
        } @else if $type == 'disabled' {
            background-color: $bg-primary-disabled !important;
        }
    }
    @include press {
        @if $type == 'primary' {
            background-color: $bg-primary-press !important;
        } @else if $type == 'secondary' {
            background-color: $bg-second-press !important;
            border: 1px solid #9DA4B6;
        } @else if $type == 'disabled' {
            background-color: $bg-primary-disabled !important;
        }
    }
}

@mixin hover {
    &:hover { @content; }
}
@mixin focus {
    &:focus { @content; }
}
@mixin press {
    &:active { @content; }
}

$theme-colors: () !default;
$theme-colors: map-merge(
    (
        "primary"   :       $bg-primary-enable,
        "secondary" :       $bg-second-enable,
        "success"   :       $success-midtone,
        "info"      :       $info-midtone,
        "warning"   :       $warning-midtone,
        "error"     :       $error-midtone,
        "disabled"  :       $bg-primary-disabled
    ),
    $theme-colors
);

@each $color, $value in $theme-colors {
    .btn-#{$color} {
        @include button-variant($color, $value);
    }
    .color-#{$color} {
        color: $value;
    }
    .bg-#{$color} {
        background-color: $value !important;
    }
}

$colors: ("wsp_cl1": "#0F24B5", "wsp_cl2": "#F93B47", "wsp_cl3": "#5BD262", "wsp_cl4": "#A401BA", "wsp_cl5": "#45650F", "wsp_cl6": "#1B3C01", "wsp_cl7": "#3D17C9", "wsp_cl8": "#22FEA4", "wsp_cl9": "#6F5B18", "wsp_cl10": "#C6B6E9", "wsp_cl11": "#F07AEE", "wsp_cl12": "#336387", "wsp_cl13": "#2D94E4", "wsp_cl14": "#D343E0", "wsp_cl15": "#6B9E4F", "wsp_cl16": "#7DE2DD");

@each $name, $color in $colors {
    .#{$name} {
    background-color: #{$color} !important;
  }
}

$categories:("category_cl1":"#005496", "category_cl2":"#ABE1FA", "category_cl3":"#FAA61A", "category_cl4":"#FFE293", "category_cl5":"#62136D", "category_cl6":"#C7A0CB", "category_cl7":"#79A342", "category_cl8":"#E8EEAE", "category_cl9":"#CB582C", "category_cl10":"#FDD1B0", "category_cl11":"#00707E", "category_cl12":"#92D6E3", "category_cl13":"#2D94E4", "category_cl14":"#D343E0", "category_cl15":"#6B9E4F", "category_cl16":"#7DE2DD");
@each $name, $color in $categories {
    .#{$name} {
    background-color: #{$color} !important;
  }
}
.input-adornment  input::-webkit-input-placeholder {
	color: #333333 !important;
}
.MuiButton-root {
    font-size: $font-body;
    padding: 12px 32px !important;
    text-transform: initial !important;
    color: #F9FBFC !important;
}
.MuiButton-label {
    font-weight: 300 !important;
}
.MuiTypography-root, .MuiTableContainer-root, .MuiTable-root, .MuiTableCell-root {
    font-family: $font-family-sans-serif !important;
}
// .MuiFilledInput-root {
//     border-top-left-radius: 8px !important;
//     border-top-right-radius: 8px !important;

//     .MuiFilledInput-input {
//         background-color: #FFFFFF;
//         padding: 10.5px !important;
//         border-radius: 8px !important;
//         border-top-left-radius: none !important;
//         border-top-right-radius: none !important;
//     }
// }
.MuiInputBase-root {
    font-family: $font-family-sans-serif !important;
    border: $border-input;
    font-size: $font-body;
    box-sizing: border-box;
    border-radius: 8px;

    .MuiInputBase-input {

    }
    .MuiInputBase-fullWidth {
        // margin-bottom: 16px;
    }
    .MuiInputBase-inputMultiline {
        padding: 0
    }
    .MuiInputBase-multiline {
        padding: 12px 16px;
        border-radius: 4px;
    }
}

.MuiSelect-root {
    background-color: #FFFFFF !important;
    /* /Primary/Dark/20 */
}
.MuiSelect-select {
    background-color: #FFFFFF;
    padding: 10px !important;
    // border: 1px solid #CED1DA !important;
    // box-sizing: border-box;
    border-radius: 8px !important;
    border-top-left-radius: none !important;
    border-top-right-radius: none !important;
}
.MuiFilledInput-underline:before, .MuiFilledInput-underline:after {
    border-bottom: none !important;
}
.MuiInput-underline:after, .MuiInput-underline:before {
    border-bottom: none !important;
}
@for $i from 1 through 10 {
    .h-#{$i*8} {
        height: #{$i*8}px;
    }
    .w-#{$i*8} {
        width: #{$i*8}px;
    }
    .m-#{$i*8} {
        margin: #{$i*8}px;
    }
    .ml-#{$i*8} {
        margin-left: #{$i*8}px !important;
    }
    .mr-#{$i*8} {
        margin-right: #{$i*8}px !important;
    }
    .mt-#{$i*8} {
        margin-top: #{$i*8}px !important;
    }
    .mb-#{$i*8} {
        margin-bottom: #{$i*8}px !important;
    }

    .p-#{$i*8} {
        padding: #{$i*8}px;
    }
    .pl-#{$i*8} {
        padding-left: #{$i*8}px !important;
    }
    .pl-#{$i*10} {
        padding-left: #{$i*10}px !important;
    }
    .pr-#{$i*8} {
        padding-right: #{$i*8}px !important;
    }
    .pt-#{$i*8} {
        padding-top: #{$i*8}px !important;
    }
    .pb-#{$i*8} {
        padding-bottom: #{$i*8}px !important;
    }
    .px-#{$i*8} {
        padding-left: #{$i*8}px;
        padding-right: #{$i*8}px;
    }
    .py-#{$i*8} {
        padding-bottom: #{$i*8}px;
        padding-top: #{$i*8}px;
    }
}
label {
    margin-bottom: 0;
    display: block;
}
.d-flex {
    display: flex;
}
.a-item-center {
    align-items: center;
}
.border-dotted {
    border: 1px dashed #CED1DA;
}

.template-page {
    width: 578px !important;
    height: 728px !important;
    margin: auto;
    border-radius: 8px;
    box-shadow: 0px 10px 10px rgba(10, 27, 72, 0.1);
    position: relative;
        background-color: $body-bg;
        height: 100%;
        padding: $distance*8;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
    // &-left {
        // &-title {
        //     font-family: 'Inter';
        //     font-style: normal;
        //     font-weight: bold;
        //     font-size: 16px;
        //     line-height: 24px;
        //     color: #646465;
        //     position: absolute;
        //     top: 24px;
        // }
        .block-left {
            max-width: 149.84px;
        }

        .affiche {
            float: right;
        }
        .content{
            background-color: #FFF;
            position: relative;

            .t-category-right {
                border-color: #ABE1FA transparent #ABE1FA transparent;
                border-width: 0px 0px 35px 50px;
                width: 185.64px;
                border-style: solid;
                transform: rotateZ(270deg);
                text-transform: uppercase;
                position: absolute;
                right: -76px;
                top: 76px;

                label {
                    position: absolute;
                    top: 10px;
                    right: 24px;
                    font-family: 'Oswald' !important;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 11px;
                    line-height: 13px;
                }
            }
            .category {
                position: relative;
                padding-top: 24px;
                width: 149.84px;
                height: 241.5px;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                .t-category {
                    position: absolute;
                    top: 44px;
                    left: -8px;
                    font-family: 'Oswald' !important;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 11px;
                    line-height: 13px;
                    display: flex;
                    align-items: center;
                    text-align: right;
                    letter-spacing: 0.03em;
                    text-transform: uppercase;
                    color: #000000;
                    transform: rotate(-90deg);
                }

                .b-time {
                    display: flex;
                    align-items: center;
                    float: right;
                    margin-top: 8px;
                }
                .time {
                    font-family: 'Oswald' !important;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 21px;
                    line-height: 25px;
                    align-items: center;
                    letter-spacing: 0.03em;
                    text-transform: lowercase;
                    color: #000000;
                }
                .duree {
                    font-family: 'Oswald' !important;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 8px;
                    line-height: 8px;
                    align-items: center;
                    letter-spacing: 0.03em;
                    text-transform: lowercase;
                    color: #000000;
                }
            }
            .d-lieux {
                .black {
                    width: 112.88px;
                    height: 35px;
                    background-color: #000000;
                    color: #FFF;
                    .t-above {
                        font-family: 'Oswald' !important;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 9px;
                        line-height: 9px;
                    }
                    .t-under {
                        font-family: 'Oswald' !important;;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 7px;
                        line-height: 8px;
                    }
                }
                .white {
                    width: 112.88px;
                    height: 35px;
                    font-family: 'Oswald' !important;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 10px;
                    line-height: 11px;
                    display: flex;
                    .bevel {
                        border-color: #005496 transparent #005496 transparent;
                        border-width: 0px 21px 35px 0px;
                        height: 0;
                        width: 43px;
                        border-style: solid;
                        color: #fff;
                    }
                    .number {
                        label {
                            font-family: 'Oswald' !important;
                            font-style: normal;
                            line-height: 10px;
                            &:first-child {
                                font-size: 10px;
                                font-weight: bold;
                            }
                            &:nth-child(2) {
                                font-size: 8px;
                                font-weight: normal;
                                letter-spacing: -0.01em;
                                text-transform: lowercase;
                                position: absolute;
                            }
                        }

                    }
                    .text {
                        span {
                            font-family: 'Oswald' !important;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 8px;
                            line-height: 10px;
                        }
                    }
                }
                .photo-name {
                    font-family: 'Roboto' !important;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 6px;
                    line-height: 8px;
                    color: #000000;
                    padding-bottom: 5px;
                }
                .corner {
                    width: 50px;
                    border-style: solid;
                    border-color: #ABE1FA transparent #ABE1FA transparent;
                    border-width: 10px 0 0 8px;
                    float: right;
                }

            }
            .page-title {
                font-family: 'Oswald' !important;
                font-style: normal;
                font-weight: bold;
                font-size: 24px;
                line-height: 26px;
                letter-spacing: 0.03em;
                color: #005496;

            }
            .sub-title {
                font-family: 'Oswald' !important;
                font-style: normal;
                font-weight: bold;
                font-size: 12px;
                line-height: 14px;
                letter-spacing: 0.03em;
                color: #005496;
                margin-top: 6px;
            }
            .p-item {
                line-height: 20px;
                .name {
                    font-family: 'Roboto' !important;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 9px;
                    line-height: 12px;
                    color: #000000;
                }
                .value {
                    font-family: 'Roboto' !important;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 9px;
                    line-height: 12px;
                    color: #000000;
                }
            }
        }
        .d-bottom {
            display: flex;
            height: 68px;
            background-color: #FFFFFF;
            .i-logo {
                width: 42px;
                height: 42px;
            }
            .b-left {
                border-color: #ABE1FA transparent #ABE1FA transparent;
                border-width: 0px 40px 68px 0px;
                width: 191.39px;
                border-style: solid;
                position: relative;
                label {
                    font-family: 'Roboto Condensed' !important;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 8px;
                    line-height: 9px;
                    &:first-child {
                        font-weight: bold;
                        text-align: right;
                        text-transform: uppercase;
                        margin-top: 10px;
                        margin-right: 10px;
                    }
                    &:nth-child(2) {
                        position: absolute;
                        top: 20px;
                        right: 4px;
                    }
                    &:nth-child(3) {
                        position: absolute;
                        top: 30px;
                        right: -3px;
                    }
                    &:nth-child(4) {
                        position: absolute;
                        top: 40px;
                        right: -8px;
                    }
                }
                .t-page {
                    position: absolute;
                    top: 40px;
                    left: 12px;
                }
            }
            .b-right {
                position: relative;
                width: 188px;
                label {
                    font-family: 'Roboto Condensed' !important;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 8px;
                    line-height: 10px;
                    &:nth-child(2) {
                        font-weight: bold;
                        margin-left: 4px;
                    }
                    &:nth-child(3) {
                        margin-left: 8px;
                    }
                    &:nth-child(4) {
                        margin-left: 12px;
                    }

                }
                .t-page {
                    font-family: 'Oswald' !important;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 7px;
                    line-height: 9px;
                    position: absolute;
                    right: 0;
                    bottom: 20px;
                }
            }
        }
        .block-right {
            position: relative;

        }
    //}
    .f-right {
        float: right;
    }
    label {
        margin-bottom: 0;
        display: block;
    }
    .in-mini {
        width: 94px;
    }
    .in-unit {
        width: 308px
    }
    .t-link {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        text-decoration-line: underline;
        color: #0F24B5;

        img {
            width: 20px;
        }
    }
    .d-btn {
        display: flex;
        justify-content: space-evenly;
    }
    p {
        margin-bottom: 0;
    }
    .mt-6 {
        margin-top: 6px;
    }
}

input:-internal-autofill-selected {

    background-image: none !important;
    background-color: #FFF !important;
    color: $input-text-color !important;
}

iframe {
    top: 128px !important;
    left: 115px !important;
    //right: 500px !important;
    width: 67.8999% !important;
}

.MuiPickersModal-dialogRoot {
    .MuiButton-textPrimary {
        span {
            color: #0F24B5 !important;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400 !important;
        }

    }
}
.MuiPickersToolbar-toolbar {
    .MuiGrid-justify-content-xs-center {
        >div {
            &:first-child {
                .MuiTypography-h4 {
                    font-size: 17px !important;
                }
            }
            &:nth-child(2) {
                .MuiPickersToolbarButton-toolbarBtn {
                    max-width: 25px !important;
                }
            }
        }
    }
}
.date-field {
    .MuiOutlinedInput-root {
        border-radius: 8px;

        .MuiOutlinedInput-input {
            padding: 0 15px;
            height: 40px;
            color: #071332;
            font-size: 16px;
            line-height: 24px;
        }
        .MuiOutlinedInput-notchedOutline {
            border-color: transparent;
        }
    }

}

.modal-container {
    z-index: 99999 !important;
}
.MuiAutocomplete-inputRoot {
    height: 40px;
    border-radius: 8px !important;
    border: none;
    input.MuiAutocomplete-input {
        padding-top: 0 !important;
    }
}

* {
	box-sizing: border-box;
  }
iframe {
	display: none !important;
}
html, body, div, span, applet, object, iframe,h1, h2, h3, h4, h5, h6, p{
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
}
html,body {
	background-color: $body-bg;
	color: $color-default;
	font-family: $font-family-sans-serif;
	font-size: $font-body;
	line-height: $lineheight-body;
	font-weight: $font-weight-body;
}
a {
	cursor: pointer;
	text-decoration: none !important;
}
//GUI Master
html,body,#root{
	height: 100%;
}
.cursor-pointer{
	cursor: pointer;
}
a:hover{
	text-decoration: none !important;
}
.bold{
	font-weight: 700;
}
.w-100 {
	width: 100%;
}
.h-100 {
	height: 100%;
}
.wrap-menu{
	width: 16.666667%;
	position: fixed;
	.menuItem{
		display: none;
	}
	.show{
		display: block;
	}
}
.gui-menu {
	background-color: #4E73DF;
	color: $color-fff;
}

.color {
	width: 25px;
	height: 25px;
	border-radius: 50%;
}

h1 {
	font-weight: 700 !important;
	font-size: $font-h1 !important;
	line-height: $lineheight-h1 !important;
	margin: 0;
}

h2 {
	font-weight: 700 !important;
	font-size: $font-h2 !important;
	line-height: $lineheight-h2 !important;
	margin: 0;
}

h3 {
	font-weight: 700 !important;
	font-size: $font-h3 !important;
	line-height: $lineheight-h3 !important;
	margin: 0;
}
small{
	font-weight: 300;
	font-size: $font-caption !important;
	line-height: $lineheight-caption !important;
}
.text-uppercase {
	text-transform: uppercase;
}
/* Text */
.text-negatif {
	color: $primary-light00 !important;
}

.text-light {
	color: $primary-dark40 !important;
}

.text-medium {
	color: $primary-light90 !important;
}

.text-dark {
	color: $primary-dark80 !important;
}

.text-headline {
	color: $primary-dark00 !important;
}
.title-wsp {
	overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    max-width: 350px;
	font-size: 12px;
	font-weight: 700;

}
.text-link01 {
	color: $accent01-00 !important;
}

.text-link02 {
	color: $accent02-00 !important;
}

/* Background */
.bgc-primary {
	background: $primary-light00;
}

.bgc-secondary {
	background: $secondary00;
}

.bgc-midtone {
	background: $primary-midtone00;
}

.bgc-dark {
	background: $primary-dark00;
}

/* Shadow */
.shadow-soft{
	box-shadow: $shadow-soft !important;
}
.shadow-medium{
	box-shadow: $shadow-medium !important;
}
.shadow-high{
	box-shadow: $shadow-high !important;
}

// Button
.btn{
	white-space: nowrap;
	border-radius: $btn-border-radius !important;
	box-shadow: none !important;
	&:hover{
		box-shadow: $btn-hover-shadow !important;
	}
}
.btn-pri{
	background: $btn-primary-bg !important;
	&:hover{
		background: $btn-hover!important;
	}
	&:focus{
		background: $btn-focus!important;
	}
	&:disabled{
		background: $btn-primary-disabled!important;
	}
	span{
		font-size: $font-body;
		line-height: $lineheight-body;
		font-weight: 300;
		color: $btn-primary-text;
	}
}
.btn-pri.text-medium{
	span{
		color: $primary-light90;
	}
}
.btn-sec{
	background: $btn-secondary-bg !important;
	border:1px solid $btn-secondary-border !important;
	&:focus{
		background: $btn-secondary-focus-bg!important;
	}
	&:disabled{
		background: $btn-secondary-disabled-bg!important;
		border:1px solid $btn-secondary-disabled !important;
		svg{
			color: $btn-secondary-color-icon-disable !important;
		}
	}
	span{
		font-size: $font-body;
		line-height: $lineheight-body;
		font-weight: 300;
		color: $btn-secondary-text;
	}
	svg{
		color: $btn-secondary-color-icon !important;
	}
}
.btn-lr{
	padding: $btn-lr-padding !important;
}
.btn-lr-icon{
	padding: $btn-lr-padding-icon !important;
}
.btn-sm{
	padding: $btn-sm-padding !important;
}
.btn-xs{
	padding: $btn-xs-padding !important;
}
.btn-sm-icon{
	padding: $btn-sm-padding-icon !important;
}
.MuiCheckbox-colorSecondary.Mui-checked {
    color: $checkbox-bg-checked !important;
}
.btn-circle{
	border-radius: 50% !important;
}

//Radio
.radioCustom{
	&:checked {
		position: absolute;
		left: -9999px;
		+ {
			label {
				position: relative;
				padding-left: 37px;
				cursor: pointer;
				display: inline-block;
				font-size: $radio-label-font-size;
				line-height: $radio-label-line-height;
				font-weight: $radio-label-font-weight;
				color: $radio-label-color;
				&:before {
					content: '';
					position: absolute;
					left: 0;
					top: 0;
					bottom: 0;
					margin: auto;
					width: 18px;
					height: 18px;
					border: $radio-border-checked;
					border-radius: 100%;
					background: #fff;
				}
				&:after {
					content: '';
					width: 12px;
					height: 12px;
					background: $radio-bg-checked;
					position: absolute;
					top: 6px;
					left: 3px;
					border-radius: 100%;
					-webkit-transition: all 0.2s ease;
					transition: all 0.2s ease;
					opacity: 1;
					-webkit-transform: scale(1);
					transform: scale(1);
				}
			}
		}
	}
	&:not(:checked) {
		position: absolute;
		left: -9999px;
		+ {
			label {
				position: relative;
				padding-left: 37px;
				cursor: pointer;
				display: inline-block;
				font-size: $radio-label-font-size;
				line-height: $radio-label-line-height;
				font-weight: $radio-label-font-weight;
				color: $radio-label-color;
				&:before {
					content: '';
					position: absolute;
					left: 0;
					top: 0;
					bottom: 0;
					margin: auto;
					width: 18px;
					height: 18px;
					border: $radio-border;
					border-radius: 100%;
					background: #fff;
				}
				&:after {
					content: '';
					width: 12px;
					height: 12px;
					background: #F87DA9;
					position: absolute;
					top: 4px;
					left: 4px;
					border-radius: 100%;
					-webkit-transition: all 0.2s ease;
					transition: all 0.2s ease;
					opacity: 0;
					-webkit-transform: scale(0);
					transform: scale(0);
				}
			}
		}
		&:hover{
			+ {
				label {
					position: relative;
					padding-left: 37px;
					cursor: pointer;
					display: inline-block;
					font-size: $radio-label-font-size;
					line-height: $radio-label-line-height;
					font-weight: $radio-label-font-weight;
					color: $radio-label-color;
					&:before {
						content: '';
						position: absolute;
						left: 0;
						top: 0;
						bottom: 0;
						margin: auto;
						width: 18px;
						height: 18px;
						border: $radio-border-hover;
						border-radius: 100%;
						background: $radio-bg-hover;
					}
					&:after {
						content: '';
						width: 12px;
						height: 12px;
						background: #F87DA9;
						position: absolute;
						top: 4px;
						left: 4px;
						border-radius: 100%;
						-webkit-transition: all 0.2s ease;
						transition: all 0.2s ease;
						opacity: 0;
						-webkit-transform: scale(0);
						transform: scale(0);
					}
				}
			}
		}
	}
}
.MuiSvgIcon-root{
	color: $primary-dark20;
}
.Mui-checked .MuiSvgIcon-root{
	color: $radio-bg-checked;
}
//CheckBox Custom
.styled-checkbox {
	position: absolute; // take it out of document flow
	opacity: 0; // hide it

	& + label {
	  position: relative;
	  cursor: pointer;
	  padding: 0;
	}

	// Box.
	& + label:before {
	  content: '';
	  margin-right: 11px;
		margin-top: 1px;
	  display: inline-block;
	  vertical-align: bottom;
	  width: 18px;
	  height: 18px;
	  background: white;
	  border: $checkbox-border;
	  border-radius: $checkbox-border-radius;
	}
	& + label:before {
	  content: '';
	  margin-right: 11px;
		margin-top: 1px;
	  display: inline-block;
	  vertical-align: bottom;
	  width: 18px;
	  height: 18px;
	  background: white;
	  border: $checkbox-border;
	//   border-radius: 20px;
	}

	// Box hover
	&:hover + label:before {
	  background: $checkbox-bg-hover;
	  border: $checkbox-bg-hover;
	}


	// Box checked
	&:checked + label:before {
	  background: $checkbox-bg-checked;
	  border: $checkbox-bg-checked;
	  border-radius: $checkbox-border-radius;
	}

	// Disabled state label.
	&:disabled + label {
	  cursor: auto;
	  color: $checkbox-label-color;
	  font-size: $checkbox-label-font-size;
	  font-weight: $checkbox-label-font-weight;
	  line-height: $checkbox-label-line-height;
	}

	// Disabled box.
	&:disabled + label:before {
	  box-shadow: none;
	  background: #ddd;
	}

	// Checkmark. Could be replaced with an image
	&:checked + label:after {
	  content: "";
	  background: url("http://localhost:3000/svg/check-white.svg") no-repeat;
	  left: 1px;
	  top: 9px;
	  width: 16px;
	  height: 12px;
	  position: absolute;
	  display: inline-block;
	}
}

.checkBoxCircle {
	width: 35px;
    height: 35px;
    border-radius: 50%;
	position: relative;
	cursor: pointer;
}

.ischecked {
	background: url("http://localhost:3000/svg/check-white.svg") no-repeat;
	left: 9px;
	top: 10px;
	width: 16px;
	height: 12px;
	position: absolute;
	display: inline-block;
}



//Tab
.MuiTab-wrapper{
	text-transform: capitalize;
	font-size: $tab-fontsize;
	font-weight: $tab-fontweight;
	line-height: $tab-lineheight;
}
.MuiTab-textColorPrimary{
	color: $tab-text-color !important;
}
.MuiTab-textColorPrimary.Mui-selected {
	color: $tab-text-color-active !important;
}
.PrivateTabIndicator-colorPrimary-16 {
	background-color: $tab-text-color-active !important;
}
.MuiTab-labelIcon{
	.MuiTab-wrapper{
		flex-direction: row;
		svg{
			margin-bottom: 0 !important;
			margin-right: 13px;
		}
	}
}
//Avatar
.avatar {
	cursor: pointer;
    display: flex;
    overflow: hidden;
    position: relative;
    align-items: center;
    flex-shrink: 0;
    user-select: none;
    border-radius: 50% !important;
    justify-content: center;
    font-family: $font-family-sans-serif;
	background-color: $avatar-bg;
	color: $avatar-text-color;
	font-weight: $avatar-font-weight;
	object-fit: cover;
	text-transform: uppercase;
}
.avatar-1{
	width: $avatar-width-height-1;
	height: $avatar-width-height-1;
	font-size: $avatar-fontsize-normal;
	line-height: $avatar-lineheight-normal;
}
.avatar-2{
	width: $avatar-width-height-2;
	height: $avatar-width-height-2;
	font-size: $avatar-fontsize-normal;
	line-height: $avatar-lineheight-normal;
}
.avatar-3{
	width: $avatar-width-height-3;
	height: $avatar-width-height-3;
	font-size: $avatar-fontsize-normal;
	line-height: $avatar-lineheight-normal;
}
.avatar-4{
	width: $avatar-width-height-4;
	height: $avatar-width-height-4;
	font-size: $avatar-fontsize-small;
	line-height: $avatar-lineheight-small;
}
.avatar-5{
	width: $avatar-width-height-5;
	height: $avatar-width-height-5;
	font-size: $avatar-fontsize-small;
	line-height: $avatar-lineheight-small;
}
//Sorting

.wrap-sorting {
	width: $sorting-width;
	height: $sorting-height;
	.wrapInput{
		position: relative;
		.sorting{
			border: $sorting-border;
			border-radius: $sorting-borderradius;
			background: $sorting-bg;
			width: $sorting-width;
			height: $sorting-height;
			font-size: $sorting-fontsize;
			line-height: $sorting-lineheight;
			box-sizing: border-box;
			padding: $sorting-padding;
			pointer-events: none;
		}
		.sorting.active {
			border: $sorting-focus-border;
			background: $sorting-focus-bg;
			outline: 0;
		}
		.icon-left{
			left: -155px;
		}
		img{
			margin: auto;
			position: absolute;
			top: 0; bottom: 0; right: 13px;
		}
	}
	ul{
		width: $sorting-width !important;
		background: $sorting-bg;
		border: $sorting-item-border;
		border-radius: $sorting-item-border-radius;
		padding: 16px 0;
		position: absolute;
		z-index: 999;
		li{
			display: flex;
			align-items: center;
			list-style: none;
			padding: $sorting-item-padding;
			&:hover{
				background: $sorting-item-hover-color;
				cursor: pointer;
			}
			font-size: $sorting-item-fontsize;
			line-height: $sorting-item-lineheight;
		}
	}
	ul.show{
		display: block;
	}
}
//Input
.input{
	width: $input-width ;
	// height: $input-height ;
	// max-height: $input-height ;
	padding: 8px;
	.MuiInputBase-root{
		background:$input-back-ground;
		height: $input-height ;
		padding: $input-padding ;
		// border: $input-border ;
		border-radius: $input-borderradius ;
	}
	.Mui-focused{
		border-color: $input-checked-border-color;
		border-width: 1px;
	}
	.Mui-error{
		border-color: $input-error-border-color;
	}
	.MuiFormHelperText-root.Mui-error {
		color: $input-error-text-color;
		font-weight: $input-error-text-fontweight;
		line-height: $input-error-text-lineheight;
		font-size: $input-error-text-fontsize;
		margin: 2px 0 0 0;
	}
	.MuiInputBase-root.Mui-disabled{
		background: $input-disabled-bg;
		input{
			color:$input-disabled-text-color;
		}
		.MuiOutlinedInput-notchedOutline{
			border-color: #CED1DA !important;
		}
	}
	input{
		padding: 0 15px;
		height: $input-height;
		color: $input-text-color;
		font-size: $input-text-fontsize;
		line-height: $input-text-lineheight;

	}
	input::placeholder{
		color: $input-placeholder-color;
	}
	.MuiOutlinedInput-notchedOutline {
		border: 0;
	}
	.MuiInputAdornment-positionStart {
		margin-right: 6px;
	}
	.MuiIconButton-root{
		padding: 0;
	}
	&.muiSelect{
		.MuiSelect-selectMenu{
			padding: 8.5px !important;
		}
	}

	img.i-count {
		cursor: pointer;
	}
}
.muiSelect{
	.Mui-error{
		border-color: $input-error-border-color;
	}
	.MuiInputBase-root{
		&:hover{
			background-color: #fff;
		}
	}
	.MuiFilledInput-root.Mui-focused{
		background-color: #fff;
	}
	.MuiV5-MuiOutlinedInput-input { 
		padding: 0 !important;
	}
}
.typePassword{
	&:hover{
		border-color: $input-hover-border-color;
	}
	&:focus-within{
		border-color: $input-hover-border-color;
	}
	background: $input-back-ground;
	input{
		color:$input-text-color;
	}
}
label + .MuiInput-formControl {
    margin-top: 0 !important;
}

.inputSuccess{
	.MuiInputBase-root{
		border-color: $input-success-border-color ;
	}
	.MuiInputAdornment-positionEnd {
		color:$input-success-icon-color;
	}
}
.inputWarning{
	.MuiInputBase-root{
		border-color: $input-warning-border-color ;
	}
	.MuiFormHelperText-contained {
		color: $input-warning-text-color;
		font-weight: $input-error-text-fontweight;
		line-height: $input-error-text-lineheight;
		font-size: $input-error-text-fontsize;
		margin: 2px 0 0 0;
	}
}
.inputError{
	.MuiInputBase-root{
		border-color: $input-error-border-color ;
	}
	.MuiFormHelperText-contained {
		color: $input-error-text-color;
		font-weight: $input-error-text-fontweight;
		line-height: $input-error-text-lineheight;
		font-size: $input-error-text-fontsize;
		margin: 2px 0 0 0;
	}
}
.inputLabel{
	margin-bottom: 8px;
	color: $input-label-color;
	font-size: $input-label-fontsize;
	line-height: $input-label-lineheight;
	// text-transform: capitalize;
	img{
		width: 15px;
		height: 15px;
		margin-left: 4.5px;
	}
}
.inputLabelCount{
	width: $textarea-width;
	display: flex;
	justify-content: space-between;
	span{
		color: $input-label-color;
		font-size: $input-label-fontsize;
		line-height: $input-label-lineheight;
	}
	.inputLabel img {
		margin-left: 9.5px;
	}
}
//TextArea
.textarea{
	width: $textarea-width;
	// height: $textarea-height;
	border: $textarea-border;
	border-radius: $textarea-borderradius;
	color: $textarea-text-color;
	padding: $textarea-padding;
	font-size: $textarea-text-fontsize;
	line-height: $textarea-text-lineheight;
	&::placeholder{
		color: $textarea-text-placeholder;
	}
	&:focus-visible {
		border: $textarea-border-active;
		outline: 0;
	}
}
.textarea.error{
	border-color: $textarea-border-error;
}
.textarea.warning{
	border-color: $textarea-border-warning;
}
.textarea.info{
	border-color: $textarea-border-info;
}
.textarea-feedback{
	font-size: $textarea-feedback-fontsize;
	font-weight: $textarea-feedback-fontweight;
	line-height: $textarea-feedback-lineheight;
	margin-top: -4px;
	margin-bottom: 0;
}
.textarea-feedback.error{
	color: $textarea-border-error;
}
.textarea-feedback.warning{
	color: $textarea-border-warning;
}
.textarea-feedback.info{
	color: $textarea-border-info;
}
.clearfix::after {
	content: "";
	clear: both;
	display: table;
  }
//Select
.dataDropdown {
	width: $datadropdown-width;
	position: relative;
	.wrapInput{
		cursor: pointer;
		.input{
			border-radius: $input-borderradius ;
			border: $input-border ;
			padding: $input-padding !important;
			background: $datadropdown-bg;
			pointer-events: none;
			color:  $datadropdown-color;
			&::placeholder{
				color:  $datadropdown-color;
			}
		}
		.input.active {
			border : $datadropdown-active-border;
			background:$datadropdown-active-bg;
			outline: 0;
		}
		img{
			margin: auto;
			position: absolute;
			top: 0; bottom: 0; right: 17px;
		}
	}
	ul{
		width: $datadropdown-width;
		background: $datadropdown-bg;
		border: $datadropdown-item-border;
		border-radius: $datadropdown-item-border-radius;
		padding: 16px 0;
		display: none;
		position: absolute;
		z-index: 999;
		max-height: $datadropdown-height;
		overflow: auto;
		color: $datadropdown-color;
		li{
			display: flex;
			align-items: center;
			list-style: none;
			padding: $datadropdown-item-padding;
			&:hover{
				background: $datadropdown-item-hover-color;
				cursor: pointer;
			}
			font-size: $font-body;
			line-height: $lineheight-body;
		}
		hr{
			margin: 0;
		}
		.icon-left{
			margin-right: 13px;
		}
		.icon-right{
			margin-left: 13px;
		}
	}
	ul.show{
		display: block;
	}
}
.dataDropdown.secondary{
	.wrapInput{
		.input{
			background: $datadropdown-secondary-bg;
			color: $datadropdown-secondary-color;
			&::placeholder{
				color: $datadropdown-secondary-color;
			}
		}
	}
	ul{
		color: $datadropdown-secondary-color;
	}
}
//DataUpload
.wrap-dataUpload{
	.dataUpload{
		input[type="file"]{
			display: none;
		}
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: $dataupload-width;
		height: $dataupload-height;
		border-radius: $dataupload-border-radius;
		border: $dataupload-border;
		background: $dataupload-bg;
		padding: $dataupload-padding;
		p{
			margin: 0;
			font-size: $dataupload-content-fontsize;
			line-height: $dataupload-content-lineheight;
			color: $dataupload-content-text-color;
		}
		.title{
			font-size: $dataupload-title-fontsize;
			line-height: $dataupload-title-lineheight;
			color: $dataupload-title-text-color;
		}
	}
	.MuiCircularProgress-circleIndeterminate {
		stroke-dasharray: 25px, 200px;
	}
	.dataUploadFile{
		width: $dataupload-width;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 7px;
		.fileName{
			display: flex;
			justify-content: flex-start;
			align-items: center;
			color: $dataupload-filename-text-color;
			font-size: $dataupload-filename-font-size;
			line-height: $dataupload-filename-line-height;
			label{
				margin-left: 9px;
			}
		}
		.MuiLinearProgress-root{
			width: 108px;
		}
		.loading{
			display: flex;
			justify-content: space-between;
			align-items: center;
			.iconRefresh{
				margin-right: 17px;
			}
		}
	}
}
//ProjectCardGrid
.projectCardGrid{
	width: $project-card-grid-width;
	height: $project-card-grid-height;
	border-radius: $project-card-grid-border-radius;
	display: flex;
	background: $project-card-grid-bg;
	.left{
		overflow: hidden;
		width: $project-card-grid-left-width;
		border-bottom-left-radius: $project-card-grid-border-radius;
		border-top-left-radius: $project-card-grid-border-radius;
	}
	.right{
		width: calc(100% - 212px);
		padding: $project-card-grid-right-padding;
		.headline{
			display: flex;
			justify-content: space-between;
			.title{
				flex: 0 0 90%;
			}
			.icon{
				flex: 0 0 10%;
				position: relative;
				img{
					cursor: pointer;
				}
				.setting{
					position: absolute;
					top: 5px;
					left: -300px;
					z-index: 999;
					width: 294px;
					padding: 24px 24px 32px 18px;
					background: #FFFFFF;
					box-shadow: 0px 10px 10px rgba(10, 27, 72, 0.1);
					border-radius: 8px 0px 8px 8px;
					.head{
						display: flex;
						justify-content: space-between;
						margin-bottom: 21px;
						label{
							font-weight: 700;
						}
						img{
							cursor: pointer;
						}
					}
					ul{
						list-style: none;
						margin: 0;
						padding: 0;
						li{
							margin-bottom: 16px;
							&:nth-last-child(1){
								margin-bottom: 0;
							}
							a{
								color: $primary-dark80;
							}
							img{
								margin-right: 10px;
							}
						}
					}
				}
				.show{
					display: block;
				}
			}
		}
		.type{
				padding: $project-card-grid-type-padding;
				border-radius: $project-card-grid-type-border-radius;
				background: $project-card-grid-type-bg;
				color: $project-card-grid-type-text-color;
				font-size: $project-card-grid-type-text-font-size;
				font-weight: $project-card-grid-type-text-font-weight;
				line-height: $project-card-grid-type-text-line-height;
		}
		.caption{
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			.item{
				flex: 0 0 50%;
				display: flex;
				.icon{
					margin-right: 10px;
				}
				.title{
					label{
						font-size: $project-card-grid-caption-first-font-size;
						line-height: $project-card-grid-caption-first-line-height;
						font-weight: $project-card-grid-caption-first-font-weight;
						color: $project-card-grid-caption-first-text-color;
					}
					p{
						margin-bottom: 0;
						font-size: $project-card-grid-caption-second-font-size;
						line-height: $project-card-grid-caption-second-line-height;
						font-weight: $project-card-grid-caption-second-font-weight;
						color: $project-card-grid-caption-second-text-color;
						white-space: nowrap;
					}
				}
			}
		}
		hr{
			border-color: $primary-dark20;
			margin: 0;
		}
		.member{
			display: flex;
			justify-content: space-between;
			padding: $project-card-grid-member-padding;
			.avatarList{
				display: flex;
				justify-content: flex-start;
				.avatar{
					margin-right: 8px;
				}
			}
		}
	}
}
//ProjectCardList
.projectCardList{
	.icon{
		// margin: auto;
		// flex: 0 0 10%;
		position: relative;
		img{
			cursor: pointer;
		}
		.setting{
			position: absolute;
			top: 5px;
			left: -300px;
			z-index: 999;
			width: 294px;
			padding: 24px 24px 32px 18px;
			background: #FFFFFF;
			box-shadow: 0px 10px 10px rgba(10, 27, 72, 0.1);
			border-radius: 8px 0px 8px 8px;
			.head{
				display: flex;
				justify-content: space-between;
				margin-bottom: 21px;
				label{
					font-weight: 700;
				}
				img{
					cursor: pointer;
				}
			}
			ul{
				list-style: none;
				margin: 0;
				padding: 0;
				li{
					margin-bottom: 16px;
					&:nth-last-child(1){
						margin-bottom: 0;
					}
					a{
						color: $primary-dark80;
					}
					img{
						margin-right: 10px;
					}
				}
			}
		}
	}
	.tag{
		padding: $project-card-grid-type-padding;
		border-radius: $project-card-grid-type-border-radius;
		background: $project-card-grid-type-bg;
		color: $project-card-grid-type-text-color;
		font-size: $project-card-grid-type-text-font-size;
		font-weight: $project-card-grid-type-text-font-weight;
		line-height: $project-card-grid-type-text-line-height;
	}
}

//Comment
.comments{
	width: $comment-post-avatar-width;
	// border: $comment-post-avatar-border;
	padding: $comment-post-avatar-padding;
	.post{
		display: flex;
		justify-content: flex-start;
		.avatar{
			background: $comment-post-avatar-bg;
			font-weight: $comment-post-avatar-font-weight;
			color: $comment-post-avatar-text-color;
			margin-right: 13px;
		}
		.MuiCheckbox-root {
			color: $comment-post-avatar-checkbox-border-color;
		}
		.MuiTypography-body1 {
			color: $comment-post-avatar-checkbox-text-color;
			font-weight: $comment-post-avatar-checkbox-text-font-weight;
			font-size: $comment-post-avatar-checkbox-text-font-size;
			line-height: $comment-post-avatar-checkbox-text-line-height;
		}
		.MuiCheckbox-colorSecondary.Mui-checked {
			color: $comment-post-avatar-checkbox-border-checked !important;
		}
	}
	.comment{
		display: flex;
		justify-content: space-between;
		align-items: center;
		.user{
			display: flex;
			justify-content: space-between;
			align-items: center;
			label{
				font-size: $comment-user-text-font-size;
				line-height: $comment-user-text-line-height;
				font-weight: $comment-user-text-font-weight;
			}
		}
		.date{
			font-size: $comment-date-font-size;
			line-height: $comment-date-line-height;
			font-weight: $comment-date-font-weight;
		}
	}
	.content{
		color: $comment-content-text-color;
		font-size: $comment-content-font-size;
		line-height: $comment-content-line-height;
		font-weight: $comment-content-font-weight;
	}
	.action{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		a{
			text-decoration: underline;
			cursor: pointer;
			&:hover{
				color: $comment-action-hover;
			}
		}
	}
	.reply{
		display: none;
		overflow: auto;
	}
	.input::placeholder{
		color: #071332 !important;
	}
	.show{
		display: block;
	}
	.hide{
		display: none;
	}
	.listButton{
		float: right;
	}
}
.PrivateRadioButtonIcon-checked-155 svg{
	color: $accent01-00;
}
.MuiTabs-indicator{
	height: 3px !important;
}
//Table
.MuiTableRow-head{
	background: $primary-light00 !important;
}
.MuiTableCell-head{
	padding: 7px 24px !important;
}
// .MuiTableCell-body{
// 	padding: 16px 24px !important;
// }
.MuiTableCell-root{
	border-bottom: 1px solid #E0E2E6;
}
.projectTable{
	// overflow: inherit !important;
	.MuiTableCell-head{
		padding: 4px 24px !important;
	}
	.MuiTableCell-root{
		font-family: $font-family-sans-serif;
		font-size: $font-body;
		line-height: $lineheight-body;
		font-weight: $font-weight-body;
	}
}
//CheckBox
.market{
	.MuiFormControlLabel-root{
		width: 100%;
	}
	.MuiFormControlLabel-label{
		font-weight: 300 !important;
		font-size: 12px !important;
		line-height: 18px !important;
		color: #071332;
	}
}
//MarketSlide
.marketPlaceSlide{
	.wrap-slide{
		width: 802px;
		margin: auto;
	}
	.swiper {
		width: 100%;
		height: 100%;
	}
	.swiper-slide {
		text-align: center;
		font-size: 18px;
		background: #fff;

		/* Center slide text vertically */
		display: -webkit-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		-webkit-justify-content: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		-webkit-align-items: center;
		align-items: center;
	}

	.swiper-slide img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.swiper {
		width: 100%;
		height: 300px;

	}

	.swiper-slide {
		background-size: cover;
		background-position: center;
	}

	.mySwiper2 {
		height: 432px;
		border-radius: 8px;
		margin-left: auto;
		margin-right: auto;
	}

	.mySwiper {
		height: 20%;
		box-sizing: border-box;
		width: calc(100% - 94px);
		margin-left: 0;
	}

	.mySwiper .swiper-slide {
		width: 94px;
		height: 48px;
		border-radius: 4px;
		overflow: hidden;
	}

	.mySwiper .swiper-slide-thumb-active {
		opacity: 1;
	}

	.swiper-slide img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

}
//projectMenuRight
.projectMenuRight{
	.MuiTab-wrapper{
		font-weight: 300;
		font-size: 12px !important;
		line-height: 18px !important;
	}
	.MuiTypography-body1{
		font-weight: 300;
		font-size: 12px !important;
		line-height: 18px !important;
	}
	input[type=number]::-webkit-inner-spin-button,
	input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
	}
}
.projectMenuRightComment{
	.Mui-checked{
		.MuiSvgIcon-root{
			color: $success-midtone;
		}
	}

}
.show-alert-content {
	position: absolute;
    right: 30px;
    width: auto;
    z-index: 9;
    bottom: 20px;
	.alert {
		padding: 0.25rem 1.25rem;
	}
}
.transform-overflow {
	.react-transform-wrapper {
		overflow: unset !important;
	}
}

.tab-collapse {
	.image-collapse {
		width: 137px;
		padding: 1px;
    	margin: 1px 1px 5px 1px;
	}

	.image-collapse:hover {
		border: 1px solid #cfd1db;
	}
}

.tab-collapse {
	.image-collapse.active {
		border: 1px solid #0E20A3;
	}
}

.mr-right-10 {
	margin-right: 10px !important;
}
.d-none{
	display: none;
}
.show-icon-brief-photo::after {
	content: url('../assets/svg/brief-blue.svg');
	position: absolute;
    bottom: 0px;
    right: 0px;
}

.error-informations-input {
	color: #e74c3c;
    font-weight: 700;
    line-height: 18px;
    font-size: 12px;
    margin: 2px 0 0 0;
}
.lineWithText{
	width: 100%;
    height: 15px;
    border-bottom: 1px solid rgba(0,0,0,.1);
    text-align: left;
	span{
		font-size: 14px;
		background-color: white;
		padding-right: 10px;
	}
}

.font-size-14 {
	font-size: 14px;
}

.font-size-16 {
	font-size: 16px;
}

.font-size-18 {
	font-size: 18px;
}

.font-size-24 {
	font-size: 24px;
}

.container-buttom {
	display: flex;
    margin-left: 200px;

	.b-connect {
		margin: 35px 10px 0px 0px !important;
	}
}

.page-resend-mail {
	.title-resend-mail {
		color: #0A1B48;
		font-size: 19px;
		font-weight: bold;
		line-height: 25px;
	}
	
}

.page-sign-in {
	input[type="text"], textarea {
		background-color : #fff; 
	}
	
	.input .MuiInputBase-root {
		padding: 0 !important;
	}
	
	.input .MuiInputBase-root .MuiInputBase-input.MuiInput-input {
		border-radius: 8px;
		padding-left: 8px;
		padding-right: 8px;
		height: 38px;
	
	}
	.input {
		border-radius: 8px;
		padding-left: 8px;
		padding-right: 8px;
		height: 45px;
		border: 1px solid #ced1da;
	}
}


.font-text-default {
    font-family: 'Inter' !important;
    font-style: normal !important;
    font-weight: 300 !important;
    font-size: 12px !important;
    line-height: 18px !important;
    text-transform: capitalize !important;
}

.font-text-default-size-16 {
    font-family: 'Inter' !important;
    font-style: normal !important;
    font-weight: 300 !important;
    font-size: 16px !important;
    line-height: 18px !important;
    text-transform: capitalize !important;
}

.btn-outline-secondary {
	color: #fff !important;
    background-color: transparent !important;
    background-image: none !important;
    // border-color: #6c757d !important;
	border: 1px solid #6c757d7d !important;
	background: #6c757d66 !important;
}
